export const INTRO_HOME = `
<div class="reward-content">
  <div class="reward-part">
    <p style="text-align:left">
    Miss World Việt Nam 2023 (Hoa hậu Thế giới Việt Nam 2023) là cuộc thi nhan sắc cấp quốc gia, nhằm tìm kiếm người đại diện Việt Nam tham dự đấu trường nhan sắc quốc tế. Năm nay, Miss World Việt Nam trở lại với nhiều phần thi hấp dẫn thu hút sự quan tâm khắp cả nước.
    </p>
    <p style="text-align:left">
    Với những quyền lợi đặc biệt, thí sinh chiến thắng giải thưởng là người được khán giả ủng hộ và bình chọn nhiều nhất thông qua hệ thống website bình chọn chính thức của cuộc thi: <a href="https://missworldvietnam.bvote.vn" >https://missworldvietnam.bvote.vn</a>
    </p>
    </div>
  </div>
</div>
`

export const INTRO_TITLE = `Là cổng bình chọn chính thức của cuộc thi Miss World Việt Nam 2023 (Hoa hậu Thế giới Việt Nam 2023), Bvote tự hào mang đến giải pháp bình chọn trực tuyến công bằng, minh bạch cho các thí sinh.`

export const INTRO = `
  <div class="reward-content">
  <div class="reward-part">
<div class="item">
<p>
<span style="font-size:16px; font-family: roboto; ">
<span style="color:#000000">
<span style="font-family:roboto">
Cổng bình chọn Bvote sẽ đồng hành cùng cuộc thi để tìm kiếm ra những thí sinh được yêu thích nhất qua sự bình chọn của khán giả. 
  </span>
  </span>
  </span>
  </p>
   <p>
  <span style="font-size:16px"><span style="color:#000000">
  <span style="font-family:roboto;line-height: 150%;">
  Việc hoạt động dựa trên công nghệ chuỗi khối của Blockchain giúp giải pháp bình chọn của Bvote sở hữu những ưu điểm vượt trội về tính an toàn, bảo mật, đem lại kết quả công khai và minh bạch nhất. Thay vì sử dụng phương thức truyền thống bằng tin nhắn, khán giả sẽ ủng hộ cho thí sinh mình yêu thích nhất thông qua hệ thống bình chọn trên Website. Giải pháp này cho phép mọi người theo dõi kết quả liên tục, trực tiếp trên hệ thống, một điều mà các hình thức cũ không làm được. Không chỉ loại bỏ nguy cơ gian lận, Bvote còn mang đến trải nghiệm bình chọn mới lạ, tiện lợi và dễ sử dụng. Nhất là khi giải pháp bình chọn của Bvote còn kết hợp cùng các ví điện tử uy tín để đảm bảo mức độ bảo mật cao và giảm thiểu tối đa tình trạng hack vote. Cách thức bình chọn:Ban tổ chức đưa lên thông tin thí sinh để khán giả bình chọn
  <br/>
  <br/>
  </span>
    </span>
      </p> 
      <p>
      &nbsp;
      </p> 
      <p>
      <span style="font-size:16px">
      <span style="color:#000000">
      <span style="font-family:roboto">
      <strong class="item-title">
      Cách thức bình chọn:
      </strong>
      <br/>
      </span>
      </span>
      <span style="color:#000000">
      <span style="font-family:roboto"> 
      </span>
      </span>
      </span>
      </p>
      <div class="item-method">
      <div class="item-step">
       <p>
       <span style="font-size:16px">
       <span style="color:#2B2438">
       <span class="item-text" style="font-family:roboto;line-height: 150%;">
       <i>Bước 1: &nbsp;</i> Truy cập website: https://missworldvietnam.bvote.vn/. Tại phần danh sách thí sinh, click chi tiết vào thí sinh muốn bình chọn
       </span>
       </span>
       </span>
       </p> 
       </div>
       <div class="item-step">
       <p>
       <span style="font-size:16px">
       <span style="color:#2B2438">
       <span class="item-text" style="font-family:roboto;line-height: 150%;">
       <i>Bước 2: &nbsp;</i> Click bình chọn Momo. Hệ thống sẽ trả về mã QR để bình chọn thí sinh
       </span>
       </span>
       </span>
       </p>
       </div>
       <div class="item-step">
        <p><span style="font-size:16px">
        <span style="color:#2B2438">
        <span class="item-text" style="font-family:roboto;line-height: 150%;">
        <i>Bước 3: &nbsp;</i> Truy cập link thanh toán, tiến hành thanh toán (tương đương bình chọn). 

        </span>
        </span>
        </span>
        </p>
        </div>
        <div class="item-step">
         <p>
         <span style="font-size:16px">
         <span style="color:#2B2438">
         <span class="item-text" style="font-family:roboto;line-height: 150%;">
         <i>Bước 4: &nbsp;</i> Momo check thanh toán thành công, hệ thống Bvote sẽ tiến hành lưu trữ kết quả bình chọn. Phí bình chọn theo gói lựa chọn và 1 lượt bình chọn qua Momo tương đương với số điểm theo gói bình chọn mà khán giả đã chọn. Lưu ý không giới hạn số lượt bình chọn và số thí sinh bình chọn.
         </span>
         </span>
         </span>
         </p> 
         </div>
         </div>
         <br/>
          <p>
          &nbsp;
          </p> 
          <p>
          <span style="font-size:16px">
          <strong>
          <span>
          <span style="font-family:roboto" class="item-structure">
          Cơ cấu giải thưởng: 
          </span>
          </span>
          </strong>
          </span>
          </p> 
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto">
          Thí sinh yêu thích nhất sẽ nhận được giải thưởng tiền mặt trị giá 50.000.000 VNĐ và vào thẳng Top 10 chung kết Miss World  Việt Nam 2023.
          </p> 
          <br/>
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto">
          Cổng bình chọn của Bvote sẽ chính thức được mở vào 09 giờ 30 ngày 09/07/2023 và sẽ kết thúc bình chọn vào 20 giờ 45 ngày 22/07/2023 để chọn ra thí sinh vào thẳng top 10.
          </p>
          <br/>
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto; color:red;">
          Lưu ý điểm thí sinh được yêu thích nhất và vào thẳng Top 10 sẽ tính như sau:
          </p>
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto; color:red;">
          *** 80% số điểm từ khán giả bình chọn
          </p> 
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto; color:red;">
          *** 20% số điểm từ Hội đồng Ban giám khảo
          </p> 
          
          <br/>
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto;">
          Kết quả sẽ được Ban tổ chức công bố vào đêm Chung kết Toàn quốc diễn ra vào ngày 22/07/2023. Hãy ủng hộ cho những thí sinh mà bạn yêu thích nhất tiến gần hơn đến vương miện MISS WORLD VIỆT NAM bằng cách bình chọn nhé!
          </p> 
</div>
</div>
</div>
  `
