import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { closeButton } from "./PriceModal";
// import aa from 'assets/images/aa.jpg'

let countDown = "";
export const VoteMomoModal = ({ missId, combo, payUrl, onClose }) => {
  const [remainTime, setRemainTime] = useState(3);
  const [error, setError] = useState("");

  useEffect(() => {
    // Count down 3s
    let temp = 3;
    countDown = setInterval(() => {
      if (temp > 0) {
        temp = temp - 1;
        setRemainTime(temp);
      } else clearInterval(countDown);
    }, 1000);

    return () => clearInterval(countDown);
  }, []);

  return (
    <Styles className="body-modal">
      <div className="close-button" onClick={onClose}>
        {closeButton}
      </div>
      <div className="head">
        <h3 className="title">Bình chọn</h3>
      </div>
      <div className="content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            className="desc"
            style={{ textAlign: "center", textTransform: "uppercase" }}
          >
            Vui lòng chờ bấm{" "}
            <span style={{ color: "#d39c44", fontWeight: 700, fontSize: 20 }}>
              TIẾP TỤC
            </span>{" "}
            để thực hiện các bước tiếp theo
          </p>
          <div className="remain-time">
            {remainTime > 0 ? (
              <div>Vui lòng chờ {remainTime}s</div>
            ) : (
              <a
                href="/"
                className="next"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (payUrl) {
                    window.open(payUrl);
                    setError("");
                  } else {
                    e.stopPropagation();
                    setError(
                      "Đã xảy ra lỗi trong quá trình bình chọn! Xin vui lòng thử lại."
                    );
                  }
                }}
              >
                Tiếp tục
              </a>
            )}
          </div>
        </div>
        <p className="error-message">{error}</p>
        <div className="modal-video">
          <div className="video-content">
            {/* <video id="myVideo" autoPlay playsInline>
              <source src="/videos/Bvote.mp4" />
            </video> */}
            <img alt="banner" src="/images/banner.png" />
          </div>
        </div>
        {/* <p className="desc" style={{ textAlign: "center" }}>
          hoặc Click vào đây{" "}
          <a
            className="desc"
            style={{ textAlign: "center", fontWeight: "bold", fontSize: "20" }}
            href="https://hoahauhoanvuvietnam.avatarart.io/"
            target="_blank"
            rel="noreferrer"
          >
            https://hoahauhoanvuvietnam.avatarart.io/
          </a>{" "}
          để sở hữu NFT của thí sinh bạn yêu thích.
        </p> */}
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  .close-button {
    position: absolute;
    top: -20px;
    right: 0px;
    cursor: pointer;
  }
  .modal-video {
    position: relative;
    margin: 0 20px;
  }

  img {
    width: 100%;
    border-radius: 5px;
    z-index: 1;
  }

  .remain-time {
    display: inline-block;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 500;
    background: #d39c44;
    z-index: 1;
    color: white;
  }

  .error-message {
    color: red;
    text-align: center;
  }
`;
