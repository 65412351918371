import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useActivity } from "../../store";
import { getAvatarUrl } from "../../store";
const ActivityList = () => {
  const { data, getData } = useActivity();

  useEffect(() => {
    getData();
    const interval = setInterval(() => getData(), 30000);
    return () => clearInterval(interval);
  }, [getData]);
  return (
    <React.Fragment>
      {data.map((activity) => {
        const date = moment
          .utc(activity.createdAt, ["YYYY-MM-DD HH:mm", "DD[/]MM[/]YYYY HH:mm"])
          .utcOffset("+0700")
          .format("DD[/]MM[/]YYYY HH:mm");

        return (
          <tr key={activity._id}>
            <td>
              <div
                className="item-td"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url(${getAvatarUrl(
                      activity.contestantCode
                    )})`,
                  }}
                ></div>
                <div className="info">
                  <span>SBD: {activity.contestantCode}</span>
                  <p>{activity.contestantName}</p>
                </div>
                {/* <Link to={`/phieu-binh-chon/${activity.tx_id}`} className="link">
                {activity._id}
              </Link> */}
              </div>
            </td>
            <td>
              <div className="wrap">
                <Link to={`/phieu-binh-chon/${activity.txId}`} className="link">
                  {activity.voteId?.slice(0, 18) + "..."}
                </Link>
              </div>
            </td>
            <td>
              <div className="wrap">
                <Link
                  to={`/nguoi-binh-chon/${activity.voterId}`}
                  className="link"
                >
                  {activity.voterId.slice(0, 15) + "..."}
                </Link>
              </div>
            </td>
            <td>
              <div className="wrap">{date}</div>
            </td>
          </tr>
        );
      })}
    </React.Fragment>
  );
};

export default ActivityList;
