import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="bs-container">
        <div className="bs-row">
          <div className="bs-col">
            <div
              className="footer-content aos-init aos-animate"
              data-aos="fade-down"
            >
              <Link to="/" className="link-logo">
                <img src="/images/logo-footer.png" alt="" />
              </Link>
              <div className="menu">
                <ul className="footer-list">
                  <li className="footer-list__item">
                    <Link to="/" className="footer-list__link">
                      Trang chủ
                    </Link>
                  </li>
                  <li className="footer-list__item">
                    <Link to="/gioi-thieu" className="footer-list__link">
                      Giới thiệu
                    </Link>
                  </li>
                  <li className="footer-list__item">
                    <Link to="/bang-xep-hang" className="footer-list__link">
                      Bảng xếp hạng
                    </Link>
                  </li>
                </ul>
                <p className="footer-bottom">
                  Bản quyền © 2023 bởi{" "}
                  <a
                    className="link"
                    href="https://bvote.com.vn"
                    target="__blank"
                  >
                    {" "}
                    BVote
                  </a>{" "}
                  Viet Nam. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
