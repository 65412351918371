import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { ContestantsContext } from "../../../App"
import LayoutSubPage from "../../Layouts/LayoutSubPage"
import RankItem from "./RankItem"
import styled from "styled-components"

export default function RankIndex(props) {
  const [tab] = useState("voteScore")
  // const title =
  //   props.lang === 'en'
  //     ? 'Rank - Poinsettia Festival English'
  //     : 'Bảng xếp hạng - Miss World Việt Nam 2023 (Hoa hậu Thế giới Việt Nam 2023)';

  const { ranks, favorites } = useContext(ContestantsContext)
  const isVoteTab = tab === "voteScore"
  const listData = isVoteTab ? ranks : favorites
  const pointKey = isVoteTab ? "totalPoint" : "totalVote"
  return (
    <>
      <LayoutSubPage path="/bang-xep-hang">
        <>
          <Styles className="section-result">
            <div className="bs-container">
              <div className="bs-row">
                <div className="bs-col">
                  <div className="module module-result">
                    {/* <ul className="tab-module">
                    <li
                      onClick={() => setTab("voteScore")}
                      className={isVoteTab ? "active" : ""}
                    >
                      Xếp hạng điểm bình chọn
                    </li>
                    <li
                      onClick={() => setTab("voteFavourite")}
                      className={!isVoteTab ? "active" : ""}
                    >
                      Xếp hạng điểm yêu thích
                    </li>
                  </ul> */}

                    <div className="module-content">
                      <div className="wrapper-content">
                        <div className="content-title">
                          <h3 className="title">Bảng xếp hạng bình chọn</h3>
                          <p className="test">MISS WORLD VIETNAM 2023</p>
                        </div>
                        <div className="top">
                          <div className="item-row">
                            <div className="item-col top2">
                              <div
                                className="item-top-2 item clearfix aos-init aos-animate"
                                data-aos="fade-up"
                              >
                                <RankItem
                                  isVoteTab={isVoteTab}
                                  rank={2}
                                  info={listData[1] || {}}
                                  point={listData[1]?.[pointKey]}
                                />
                              </div>
                            </div>
                            <div className="item-col top1">
                              <div
                                className="item clearfix aos-init aos-animate"
                                data-aos="fade-up"
                              >
                                <RankItem
                                  isVoteTab={isVoteTab}
                                  rank={1}
                                  info={listData[0] || {}}
                                  point={listData[0]?.[pointKey]}
                                />
                              </div>
                            </div>
                            <div className="item-col top2">
                              <div
                                className="item-top-2 item clearfix aos-init aos-animate"
                                data-aos="fade-up"
                              >
                                <RankItem
                                  isVoteTab={isVoteTab}
                                  rank={3}
                                  info={listData[2] || {}}
                                  point={listData[2]?.[pointKey]}
                                />
                              </div>
                              {/* </div> */}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="bs-row row-sm-5 row-xs-10 row-md-15">
                          {(isVoteTab ? ranks : favorites)?.map(
                            (item, index) => {
                              if (index >= 3)
                                return (
                                  <div
                                    key={item?.data?.id}
                                    className="bs-col xs-50-15 sm-33-15 md-25-15 lg-25-15"
                                  >
                                    <div
                                      className="item-top-4 item clearfix aos-init aos-animate"
                                      data-aos="fade-up"
                                    >
                                      <RankItem
                                        isVoteTab={isVoteTab}
                                        rank={index + 1}
                                        info={item || {}}
                                        point={item?.[pointKey]}
                                      />
                                    </div>
                                  </div>
                                )
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Styles>
        </>
      </LayoutSubPage>
    </>
  )
}

const Styles = styled.div`
  .module-result {
    .module-content {
      /* padding-top: 60px; */
      .wrapper-content {
        .content-title {
          text-align: center;
          text-transform: uppercase;
          padding-bottom: 40px;
          .title {
            font-family: beVietnamPro-SemiBold;
            font-size: 44px;
            line-height: 150%;
            color: #2b2438;
            margin: 0;
          }
          .test {
            font-family: beVietnamPro-SemiBold;
            font-size: 36px;
            line-height: 150%;
            color: #8854df;
            margin: 0;
            padding-top: 5px;
          }
        }
        .top {
          margin: 0 85px;
          .item-row {
            display: grid;
            grid-template-columns: 29% 36% 29%;
            gap: 30px;
            .item-col {
              .item.item-top-2 {
                max-width: 100%;
                margin-top: 130px;

                .item-content {
                  background: linear-gradient(
                    110.38deg,
                    #f738e0 0%,
                    #42c6ff 100%
                  );
                  border-radius: 16px;
                  box-shadow: 4px 4px 24px rgba(166, 98, 207, 0.16);
                  padding: 5px;
                  .item-avatar {
                    float: unset;
                    margin: 0;
                    width: 100%;
                    padding: 0;
                    background: none;
                    .image {
                      .img-frame {
                        border: none;
                        img {
                          width: 100%;
                          transform: unset;
                          border-top-right-radius: 12px;
                          border-top-left-radius: 12px;
                        }
                      }
                    }
                  }
                  .item-info {
                    padding: 20px 0;
                    background: white;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    height: unset;
                    margin-left: 0;
                    .point {
                      font-size: 35px;
                      margin-bottom: 4px;
                      display: block;
                      color: #f738e0;
                      text-align: center;
                      span {
                        display: block;
                        font-family: beVietnamPro;
                        font-size: 14px;
                        line-height: 150%;
                        text-align: center;
                        color: #807b88;
                        text-transform: initial;
                      }
                    }
                    .name {
                      line-height: 150%;
                      text-align: center;
                      color: #2b2438;
                    }
                    .sbd {
                      line-height: 150%;
                      text-align: center;
                      color: #2b2438;
                    }
                  }
                }
                .ratings {
                  position: unset;
                  max-width: 100%;
                  padding-top: 23px;
                  text-align: center;
                  img {
                    width: unset;
                  }
                }
              }
              .item {
                max-width: 100%;
                .item-content {
                  background: linear-gradient(
                    110.38deg,
                    #f738e0 0%,
                    #42c6ff 100%
                  );
                  border-radius: 16px;
                  box-shadow: 4px 4px 24px rgba(166, 98, 207, 0.16);
                  padding: 5px;
                  .item-avatar {
                    float: unset;
                    margin: 0;
                    width: 100%;
                    padding: 0;
                    background: none;
                    .image {
                      .img-frame {
                        border: none;
                        img {
                          width: 100%;
                          transform: unset;
                          border-top-right-radius: 12px;
                          border-top-left-radius: 12px;
                        }
                      }
                    }
                  }
                  .item-info {
                    padding: 20px 0;
                    background: white;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    height: unset;
                    margin-left: 0;
                    .point {
                      font-size: 35px;
                      margin-bottom: 4px;
                      display: block;
                      color: #f738e0;
                      text-align: center;
                      span {
                        display: block;
                        font-family: beVietnamPro;
                        font-size: 14px;
                        line-height: 150%;
                        text-align: center;
                        color: #807b88;
                        text-transform: initial;
                      }
                    }
                    .name {
                      line-height: 150%;
                      text-align: center;
                      color: #2b2438;
                    }
                    .sbd {
                      line-height: 150%;
                      text-align: center;
                      color: #2b2438;
                    }
                  }
                  .icon-ratings {
                    display: none;
                    position: absolute;
                    top: -17px;
                    left: -24px;
                    img {
                      width: 54px;
                      height: 54px;
                    }
                  }
                }
                .ratings {
                  position: unset;
                  max-width: 100%;
                  padding-top: 23px;
                  text-align: center;
                  img {
                    width: unset;
                  }
                }
              }
            }
          }
        }
        .item.item-top-4 {
          background: #ffffff;
          border-radius: 12px;
          box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
          filter: drop-shadow(4px 4px 24px rgba(166, 98, 207, 0.16));
          position: relative;
          .item-content {
            .item-avatar {
              border-radius: 12px;
              padding: 0;
              .image {
                .img-frame {
                  img {
                    width: 100%;
                    transform: unset;
                    border-top-right-radius: 12px;
                    border-top-left-radius: 12px;
                  }
                }
              }
            }

            .item-info {
              .point {
                display: block;
                padding-top: 16px;
                font-size: 28px;
                line-height: 150%;
                text-align: center;
                color: #8854df;
                span {
                  display: block;
                  font-family: beVietnamPro;
                  font-size: 14px;
                  line-height: 150%;
                  text-align: center;
                  color: #807b88;
                  text-transform: initial;
                }
              }
              .name {
                font-size: 16px;
                line-height: 150%;
                color: #373143;
                padding: 3px;
              }
              .sbd {
                font-size: 16px;
                line-height: 150%;
                color: #2b2438;
              }
            }
          }
          .ratings {
            top: 12px;
            left: -8px;
            .wrap-rating {
              transform: unset;
              background: #8854df;
              border-radius: 4px 4px 4px 0px;

              &::before {
                display: none;
              }
              &::after {
                content: "";
                position: absolute;
                width: 0px;
                height: 0px;
                border: 4px solid transparent;
                border-top: 4px solid #8854df;
                border-right: 4px solid #8854df;
                background: transparent;
                top: 100%;
                left: 0;
              }
              .text {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    background-repeat: no-repeat;
    .module-result {
      .module-content {
        .wrapper-content {
          .top {
            margin: 0 40px;
            .item-row {
              grid-template-columns: 29% 35% 29%;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    background-repeat: no-repeat;
    .module-result {
      .module-content {
        .wrapper-content {
          .content-title {
            .title {
              font-size: 35px;
              margin: 0;
            }
            .test {
              font-size: 30px;
            }
          }
          .top {
            margin: 0;
            .item-row {
              grid-template-columns: 30% 34% 30%;
              gap: 22px;
              .item-col {
                .item.item-top-2 {
                  margin-left: 0px;
                  .item-content {
                    .item-info {
                      .point {
                        font-size: 28px;
                      }
                      .name {
                        font-size: 14px;
                      }
                    }
                  }
                }
                .item {
                  margin-left: 0px;
                  .item-content {
                    .item-info {
                      .point {
                        font-size: 28px;
                      }
                      .name {
                        font-size: 14px;
                        white-space: unset;
                      }
                    }
                  }
                }
              }
            }
          }
          .item.item-top-4 {
            .item-content {
              .item-info {
                .name {
                  font-size: 14px;
                  white-space: unset;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    background-repeat: no-repeat;
    .module-result {
      .module-content {
        .wrapper-content {
          .content-title {
            .title {
              font-size: 28px;
              margin: 0;
            }
            .test {
              font-size: 25px;
            }
          }
          .top {
            max-width: 450px;
            padding: 0 30px;
            margin: 0 auto;
            .item-row {
              grid-template-columns: 1fr;
              .item-col {
                .item.item-top-2 {
                  order: 2;
                  margin-left: 0px;
                  margin-top: 0px;
                  .item-content {
                    .item-info {
                      .point {
                        font-size: 38px;
                        span {
                          font-size: 16px;
                        }
                      }
                      .name {
                        font-size: 18px;
                        white-space: unset;
                      }
                    }
                    .icon-ratings {
                      display: block;
                    }
                  }
                  .ratings {
                    display: none;
                  }
                }
                .item {
                  order: 1;
                  margin-left: 0px;
                  margin-bottom: 50px;
                  .item-content {
                    .item-info {
                      .point {
                        font-size: 38px;
                        span {
                          font-size: 16px;
                        }
                      }
                      .name {
                        font-size: 18px;
                        white-space: unset;
                      }
                      .sbd {
                        font-size: 18px;
                      }
                    }
                    .icon-ratings {
                      display: block;
                    }
                  }
                  .ratings {
                    display: none;
                  }
                }
                &.top1 {
                  order: 1;
                }
                &.top2 {
                  order: 2;
                }
              }
            }
          }
          .item.item-top-4 {
            .item-content {
              .item-info {
                .name {
                  font-size: 14px;
                  white-space: unset;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 577px) and (min-width: 480px) {
    /* .module-result .module-content {
      padding-top: 60px;
    } */
  }
  @media only screen and (max-width: 479px) {
    background-repeat: no-repeat;
    .module-result {
      .module-content {
        .wrapper-content {
          .content-title {
            .title {
              font-size: 20px;
              margin: 0;
            }
            .test {
              font-size: 18px;
            }
          }
          .top {
            max-width: 380px;
            padding: 0 20px;
            margin: 0 auto;
            .item-row {
              grid-template-columns: 1fr;
              .item-col {
                .item.item-top-2 {
                  order: 2;
                  margin-left: 0px;
                  margin-top: 0px;
                  .item-content {
                    .item-info {
                      .point {
                        font-size: 28px;
                        span {
                          font-size: 14px;
                        }
                      }
                      .name {
                        font-size: 15px;
                        white-space: unset;
                      }
                    }
                    .icon-ratings {
                      display: block;
                    }
                  }
                  .ratings {
                    display: none;
                  }
                }
                .item {
                  order: 1;
                  margin-left: 0px;
                  margin-bottom: 30px;
                  .item-content {
                    .item-info {
                      .point {
                        font-size: 28px;
                        span {
                          font-size: 14px;
                        }
                      }
                      .name {
                        font-size: 18px;
                        white-space: unset;
                      }
                      .sbd {
                        font-size: 15px;
                      }
                    }
                    .icon-ratings {
                      display: block;
                    }
                  }
                  .ratings {
                    display: none;
                  }
                }
                &.top1 {
                  order: 1;
                }
                &.top2 {
                  order: 2;
                }
              }
            }
          }
          .item.item-top-4 {
            .item-content {
              .item-info {
                .name {
                  font-size: 14px;
                  white-space: unset;
                }
              }
            }
          }
        }
      }
    }
  }
`
